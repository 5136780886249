// IMPORT DEPENDENCIES
import React from 'react'
import Toast from './toast'
import logo from '../static/images/logo.png'
import { Icon } from './elements'
import { academicMenus, academicMenuTitle, accountMenus, accountMenuTitle, administrationMenus, administrationMenuTitle, communicationMenus, communicationMenuTitle, digitalMenus, digitalMenuTitle, mainMenus, mainMenuTitle, operationMenus, operationMenuTitle, otherMenus } from '../helpers/menu'
import { Link } from 'react-router-dom'
import translate from '../helpers/translator'
import { encrypt, getInfo, has_enable_encryption, serverUrl, storage, text, ToJSON, socketURL, user } from '../helpers'
import Humberg from './humberg'
import ThemeSwitcher from './themeSwitcher'

const Drawer = React.memo((props) => {

    // STATE MANAGEMENT
    const userLanguage = storage.retrieve("language")
    const [activeLanguage, setActiveLanguage] = React.useState(userLanguage ? userLanguage : "english")
    const [activeLink, setActiveLink] = React.useState(window.location.pathname)
    const [activeMainMenu, setActiveMainMenu] = React.useState(-1)
    const [activeCommMenu, setActiveCommMenu] = React.useState(-1)
    const [activeAccountMenu, setActiveAccountMenu] = React.useState(-1)
    const [activeOtherMenu, setActiveOtherMenu] = React.useState(-1)
    const [activeOperationMenu, setActiveOperationMenu] = React.useState(-1)
    const [activeAdministrationMenu, setActiveAdministrationMenu] = React.useState(-1)
    const [activeAcademicMenu, setActiveAcademicMenu] = React.useState(-1)
    const [activeDigitalMenu, setActiveDigitalMenu] = React.useState(-1)
    const [title, setTitle] = React.useState(translate("profile"))
    const [isOpen, setIsOpen] = React.useState(true)
    const [isPc, setIsPc] = React.useState(false);
    const ToggleSidebar = () => {
        isOpen === true ? setIsOpen(false) : setIsOpen(true);
    }

    window.onclick = (event) => {

        // GETTING CURRENT PATHNAME
        const currentPathname = window.location.pathname

        // CHANGING ACTIVE NAV LINK
        setActiveLink(currentPathname)

        // UPDATE APPBAR
        updateAppbar()

        // CLOSE ACTIVE SIDEBAR CONTENT
        if ((event.target.tagName !== "A") && (event.target.tagName !== "SPAN") && (event.target.tagName !== "I")) {
            setActiveMainMenu(-1)
            setActiveCommMenu(-1)
            setActiveOtherMenu(-1)
            setActiveDigitalMenu(-1)
            setActiveAcademicMenu(-1)
            setActiveAccountMenu(-1)
            setActiveOperationMenu(-1)
            setActiveAdministrationMenu(-1)
        }
    }

    // UPDATE APPBAR 
    function updateAppbar() {

        const currentPathname = window.location.pathname

        // eslint-disable-next-line
        mainMenus.map((menu) => {
            if (currentPathname.includes(menu.title.replace(/ /g, "-").toLowerCase()))
                setTitle(translate(menu.title))


            if (currentPathname === "/create-topic" || currentPathname === "/list-topic" || currentPathname === "/edit-topic" || currentPathname === "/view-topic" || currentPathname === "/create-reference" || currentPathname === "/list-reference" || currentPathname === "/edit-reference" || currentPathname === "/view-reference" || currentPathname === "/create-scheme-of-work" || currentPathname === "/list-scheme-of-work" || "/list-scheme-of-works" || currentPathname === "/edit-scheme-of-work" || currentPathname === "/view-scheme-of-work" || currentPathname === "/create-log-book" || currentPathname === "/list-log-book" ||currentPathname === "/list-log-books" || currentPathname === "/edit-log-book" || currentPathname === "/view-log-book" || currentPathname === "/create-lesson-plan" || currentPathname === "/list-lesson-plan" || currentPathname === "/edit-lesson-plan" || currentPathname === "/view-lesson-plan")
                setTitle(translate("Teaching tools"))
        });

        // Handle special paths separately after the map loop
        if (currentPathname === "/create-teacher-subject" || currentPathname === "/list-teacher-subject") {
            setTitle(translate("Subject"));
        }

        if ((currentPathname === "/create-allocation") || (currentPathname === "/list-allocation") || (currentPathname === "/view-allocation") || (currentPathname === "/edit-allocation") || (currentPathname === "/create-room") || (currentPathname === "/list-room") || (currentPathname === "/view-room") || (currentPathname === "/edit-room") || (currentPathname === "/create-hostel") || (currentPathname === "/list-hostel") || (currentPathname === "/view-hostel") || (currentPathname === "/edit-hostel"))
            setTitle(translate("Hostel"))

        if (currentPathname === "/create-bank-account" || currentPathname === "/list-bank-account" || currentPathname === "/edit-bank-account" || currentPathname === "/view-bank-account") {
            setTitle(translate("Transaction"));
        }

        if (currentPathname === "/create-account-group" || currentPathname === "/list-account-group" || currentPathname === "/edit-account-group" || currentPathname === "/view-account-group") {
            setTitle(translate("General ledger"));
        }

        if (currentPathname === "/create-character-category" || currentPathname === "/list-character-category" || currentPathname === "/edit-character-category" || currentPathname === "/view-character-category") {
            setTitle(translate("Character"));
        }

        if (currentPathname === "/create-result" || currentPathname === "/list-result" || currentPathname === "/list-results" || currentPathname === "/view-result" || currentPathname === "/view-results" ||  currentPathname === "/publish-result" ||currentPathname === "/view-necta-result" || currentPathname === "/view-necta-results") {
            setTitle(translate("Result"))
        }

        if (currentPathname === "/create-role" || currentPathname === "/list-role" || currentPathname === "/edit-role" || currentPathname === "/view-role")
            setTitle(translate("role"))

        if (currentPathname === "/create-warehouse" || currentPathname === "/list-warehouse" || currentPathname === "/edit-warehouse" || currentPathname === "/view-warehouse")
            setTitle(translate("warehouse"))

        if (currentPathname === "/create-attendance" || currentPathname === "/create-attendances" || currentPathname === "/list-attendance" || currentPathname === "/edit-attendance" || currentPathname === "/view-attendance")
            setTitle(translate("attendance"))

        if (currentPathname === "/create-user" || currentPathname === "/list-user" || currentPathname === "/edit-user" || currentPathname === "/view-user")
            setTitle(translate("user"))

        if (currentPathname === "/create-term" || currentPathname === "/list-term" || currentPathname === "/edit-term" || currentPathname === "/view-term")
            setTitle(translate("Term"))

        if (currentPathname === "/create-invoice" || currentPathname === "/list-invoice" || currentPathname === "/edit-invoice" || currentPathname === "/view-invoice")
            setTitle(translate("invoice"))

        if (currentPathname === "/create-subject" || currentPathname === "/list-subject" || currentPathname === "/edit-subject" || currentPathname === "/view-subject" || currentPathname === "/create-class-subject" || currentPathname === "/list-class-subject" || currentPathname === "/view-class-subject")
            setTitle(translate("Subject"))

        if (currentPathname === "/create-student" || currentPathname === "/list-student" || currentPathname === "/edit-student" || currentPathname === "/view-student")
            setTitle(translate("Student"))

        if (currentPathname === "/define-fees" || currentPathname === "/list-fees" || currentPathname === "/edit-fees" || currentPathname === "/view-fees" || currentPathname === "/unsubscribe-fees" || currentPathname === "/structure-fees" || currentPathname === "/create-fee-discount" || currentPathname === "/list-structure-fees" || currentPathname === "/edit-structure-fees")
            setTitle(translate("fees"))

        if (currentPathname === "/create-academic-year" || currentPathname === "/list-academic-year" || currentPathname === "/edit-academic-year" || currentPathname === "/view-academic-year")
            setTitle(translate("Academic year"))

        if (currentPathname === "/" || currentPathname === "/dashboard")
            setTitle(translate("Dashboard"))

        if (currentPathname === "/change-password" || currentPathname === "/edit-profile" || currentPathname === "/view-profile")
            setTitle(translate("profile"))

        if (currentPathname === "/404")
            setTitle(translate("Page not found"))

        if (currentPathname === "/system-setting")
            setTitle(translate("Settings"))

        if (currentPathname === "/analysis")
            setTitle(translate("Analysis"))

        if (currentPathname === "/create-level" || currentPathname === "/list-level" || currentPathname === "/edit-level" || currentPathname === "/view-level")
            setTitle(translate("Level"))

        if (currentPathname === "/create-message" || currentPathname === "/list-message" || currentPathname === "/edit-message" || currentPathname === "/view-message")
            setTitle(translate("message"))

        if (currentPathname === "/create-news" || currentPathname === "/list-news" || currentPathname === "/edit-news" || currentPathname === "/view-news")
            setTitle(translate("news"))

        if (currentPathname === "/create-timetable" || currentPathname === "/list-timetable" || currentPathname === "/edit-timetable" || currentPathname === "/view-timetable")
            setTitle(translate("timetable"))

        if (currentPathname === "/create-mark" || currentPathname === "/create-marks" || currentPathname === "/list-mark" || currentPathname === "/edit-mark" || currentPathname === "/view-mark")
            setTitle(translate("mark"))

        if (currentPathname === "/create-exam" || currentPathname === "/edit-info-exam" || currentPathname === "/create-info-exam" || currentPathname === "/list-info-exam" || currentPathname === "/view-info-exam" || currentPathname === "/list-exam" || currentPathname === "/edit-exam" || currentPathname === "/view-exam")
            setTitle(translate("exam"))

        if (currentPathname === "/create-grade" || currentPathname === "/list-grade" || currentPathname === "/edit-grade" || currentPathname === "/view-grade")
            setTitle(translate("grade"))

        if (currentPathname === "/create-installment" || currentPathname === "/list-installment" || currentPathname === "/edit-installment" || currentPathname === "/view-installment")
            setTitle(translate("installment"))

        if (currentPathname === "/create-class" || currentPathname === "/list-class" || currentPathname === "/edit-class" || currentPathname === "/view-class")
            setTitle(translate("class"))

        if (currentPathname === "/create-stream" || currentPathname === "/list-stream" || currentPathname === "/edit-stream" || currentPathname === "/view-stream")
            setTitle(translate("stream"))

        if (currentPathname === "/create-parent" || currentPathname === "/list-parent" || currentPathname === "/edit-parent" || currentPathname === "/view-parent")
            setTitle(translate("Parent"))

        if (currentPathname === "/create-teacher" || currentPathname === "/list-teacher" || currentPathname === "/edit-teacher" || currentPathname === "/view-teacher")
            setTitle(translate("Teacher"))

        if (currentPathname === "/create-salary" || currentPathname === "/list-salary" || currentPathname === "/create-pension" || currentPathname === "/allocate-pension" || currentPathname === "/list-pension" || currentPathname === "/view-pension" || currentPathname === "/edit-pension" || currentPathname === "/create-allowance" || currentPathname === "/allocate-allowance" || currentPathname === "/list-allowance" || currentPathname === "/view-allowance" || currentPathname === "/edit-allowance" || currentPathname === "/create-deduction" || currentPathname === "/allocate-deduction" || currentPathname === "/list-deduction" || currentPathname === "/view-deduction" || currentPathname === "/edit-deduction")
            setTitle(translate("Payroll"))

        if (currentPathname === "/create-staff" || currentPathname === "/list-staff" || currentPathname === "/edit-staff" || currentPathname === "/view-staff")
            setTitle(translate("Staff"))

        if (currentPathname === "/create-school" || currentPathname === "/list-school" || currentPathname === "/edit-school" || currentPathname === "/view-school")
            setTitle(translate("School"))

        if (currentPathname === "/create-sponsor" || currentPathname === "/list-sponsor" || currentPathname === "/edit-sponsor" || currentPathname === "/view-sponsor")
            setTitle(translate("Sponsor"))

        if (currentPathname === "/lab")
            setTitle(translate("E-learning Labs"))

        if (currentPathname === "/books")
            setTitle(translate("E-learning Books"))

        if (currentPathname === "/physics-books")
            setTitle(translate("E-learning Physics Books"))

        if (currentPathname === "/bios-lab")
            setTitle(translate(`E-learning Biology Labs`))

        if (currentPathname === "/chemistry-lab")
            setTitle(translate(`E-learning chemistry Labs`))

        if (currentPathname === "/physics-lab")
            setTitle(translate(`E-learning physics Labs`))

        if (currentPathname === "/agriculture-lab")
            setTitle(translate(`E-learning agriculture Labs`))

        if (currentPathname === "/bios-form-one-lab")
            setTitle(translate(`E-learning Form One Biology Labs`))

        // flip book
        if (currentPathname === "/flip-book/physics1tie")
            setTitle(translate("E-learning Physics Books"))

    }

    React.useEffect(() => {

        updateAppbar()

        const handleResize = () => {
            setIsPc(window.innerWidth < 1201); // 1201px = 75.0625rem
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
        // eslint-disable-next-line
    }, [])


    // TOGGLING SIDEBAR MENU
    const toggleSidebarContent = (index, menuType) => {
        if (menuType === 'main') {
            setActiveCommMenu(-1); // Close communication menu
            setActiveOtherMenu(-1); // Close other menu
            setActiveAcademicMenu(-1); // Close academic menu
            setActiveOperationMenu(-1); // Close Operation menu
            setActiveAccountMenu(-1); // Close Account menu
            setActiveDigitalMenu(-1); // Close Digital menu
            setActiveAdministrationMenu(-1); // Close administration menu
            setActiveMainMenu(index === activeMainMenu ? -1 : index);
        } else if (menuType === 'comm') {
            setActiveMainMenu(-1); // Close main menu
            setActiveOtherMenu(-1); // Close other menu
            setActiveAcademicMenu(-1); // Close academic menu
            setActiveOperationMenu(-1); // Close Operation menu
            setActiveAccountMenu(-1); // Close Account menu
            setActiveDigitalMenu(-1); // Close Digital menu
            setActiveAdministrationMenu(-1); // Close administration menu
            setActiveCommMenu(index === activeCommMenu ? -1 : index);
        } else if (menuType === 'academic') {
            setActiveMainMenu(-1); // Close main menu
            setActiveCommMenu(-1); // Close communication menu
            setActiveOtherMenu(-1); // Close other menu
            setActiveOperationMenu(-1); // Close Operation menu
            setActiveAccountMenu(-1); // Close Account menu
            setActiveDigitalMenu(-1); // Close Digital menu
            setActiveAdministrationMenu(-1); // Close administration menu
            setActiveAcademicMenu(index === activeAcademicMenu ? -1 : index);
        }
        else if (menuType === 'administration') {
            setActiveMainMenu(-1); // Close main menu
            setActiveAcademicMenu(-1); // Close academic menu
            setActiveCommMenu(-1); // Close communication menu
            setActiveOtherMenu(-1); // Close other menu
            setActiveAccountMenu(-1); // Close Account menu
            setActiveDigitalMenu(-1); // Close Digital menu
            setActiveOperationMenu(-1); // Close Operation menu
            setActiveAdministrationMenu(index === activeAdministrationMenu ? -1 : index);
        }
        else if (menuType === 'other') {
            setActiveMainMenu(-1); // Close main menu
            setActiveAcademicMenu(-1); // Close academic menu
            setActiveCommMenu(-1); // Close communication menu
            setActiveOperationMenu(-1); // Close Operation menu
            setActiveAccountMenu(-1); // Close Account menu
            setActiveDigitalMenu(-1); // Close Digital menu
            setActiveAdministrationMenu(-1); // Close administration menu
            setActiveOtherMenu(index === activeOtherMenu ? -1 : index);
        }
        else if (menuType === 'operation') {
            setActiveMainMenu(-1); // Close main menu
            setActiveAcademicMenu(-1); // Close academic menu
            setActiveCommMenu(-1); // Close communication menu
            setActiveOtherMenu(-1); // Close other menu
            setActiveDigitalMenu(-1); // Close Digital menu
            setActiveAdministrationMenu(-1); // Close administration menu
            setActiveAccountMenu(-1); // Close Account menu
            setActiveOperationMenu(index === activeOperationMenu ? -1 : index);
        }
        else if (menuType === 'account') {
            setActiveMainMenu(-1); // Close main menu
            setActiveAcademicMenu(-1); // Close academic menu
            setActiveCommMenu(-1); // Close communication menu
            setActiveOtherMenu(-1); // Close other menu
            setActiveDigitalMenu(-1); // Close Digital menu
            setActiveAdministrationMenu(-1); // Close administration menu
            setActiveOperationMenu(-1); // Close Operation menu
            setActiveAccountMenu(index === activeAccountMenu ? -1 : index);
        }
        else if (menuType === 'digital') {
            setActiveMainMenu(-1); // Close main menu
            setActiveAcademicMenu(-1); // Close academic menu
            setActiveCommMenu(-1); // Close communication menu
            setActiveOtherMenu(-1); // Close other menu
            setActiveAccountMenu(-1); // Close Account menu
            setActiveAdministrationMenu(-1); // Close administration menu
            setActiveOperationMenu(-1); // Close Operation menu
            setActiveDigitalMenu(index === activeDigitalMenu ? -1 : index);
        }
    }

    // render main menu
    const renderMainMenu = React.useCallback(() => {
        return (
            <>
                {mainMenuTitle.visible && (
                    <li className="nav-title">
                        <span>{mainMenuTitle.title}</span>
                    </li>
                )}
                {mainMenus.map((menu, index) => {
                    if (menu.visible) {
                        return (
                            <li className="nav-item" key={index} onClick={() => {
                                toggleSidebarContent(index, 'main');
                                if (!menu.hasSubMenu) {
                                    if (isPc) {
                                        setIsOpen(true);
                                    }
                                }
                            }}>
                                <Link to={menu.link}
                                    className={`nav-link ${activeLink.includes(`/${menu.title.toLowerCase()}/`) ? "active" : ""}`}
                                >
                                    <i className={`material-icons-round`}>{menu.icon}</i>
                                    <span>{translate(menu.title)}</span>
                                </Link>
                                {menu.hasSubMenu && menu.subMenu ? (
                                    <ul className={`nav-content ${activeMainMenu === index ? "show" : ""}`}>
                                        {menu.subMenu.sort((a, b) => a.title.length - b.title.length).map((subMenu, indexTwo) => (
                                            subMenu.visible ? (
                                                <li key={indexTwo}>
                                                    <Link to={subMenu.link} className={`${activeLink.includes(subMenu.link) ? "active" : ""}`} onClick={() => {
                                                        if (isPc) {
                                                            setIsOpen(true);
                                                        }
                                                    }}>
                                                        <Icon name="chevron_right" type="round" />
                                                        <span>{translate(subMenu.title)}</span>
                                                    </Link>
                                                </li>
                                            ) : null
                                        ))}
                                    </ul>
                                ) : null}
                            </li>
                        )
                    } else {
                        return null;
                    }
                })}
            </>
        );
        // eslint-disable-next-line
    }, [activeLink, activeMainMenu, isPc]);

    // render communication menu
    const renderCommuncationMenu = React.useCallback(() => {
        return (
            <>
                {communicationMenuTitle.visible && (
                    <li className="nav-title">
                        <span>{communicationMenuTitle.title}</span>
                    </li>
                )}
                {communicationMenus.map((menu, index) => {
                    if (menu.visible) {
                        return (
                            <li className="nav-item" key={index} onClick={() => {
                                toggleSidebarContent(index, 'comm');
                                if (!menu.hasSubMenu) {
                                    if (isPc) {
                                        setIsOpen(true);
                                    }
                                }
                            }}>
                                <Link to={menu.link}
                                    className={`nav-link ${activeLink.includes(`/${menu.title.toLowerCase()}/`) ? "active" : ""}`}
                                >
                                    <i className={`material-icons-round`}>{menu.icon}</i>
                                    <span>{translate(menu.title)}</span>
                                </Link>
                                {menu.hasSubMenu && menu.subMenu ? (
                                    <ul className={`nav-content ${activeCommMenu === index ? "show" : ""}`}>
                                        {menu.subMenu.sort((a, b) => a.title.length - b.title.length).map((subMenu, indexTwo) => (
                                            subMenu.visible ? (
                                                <li key={indexTwo}>
                                                    <Link to={subMenu.link} className={`${activeLink.includes(subMenu.link) ? "active" : ""}`} onClick={() => {
                                                        if (isPc) {
                                                            setIsOpen(true);
                                                        }
                                                    }}>
                                                        <Icon name="chevron_right" type="round" />
                                                        <span>{translate(subMenu.title)}</span>
                                                    </Link>
                                                </li>
                                            ) : null
                                        ))}
                                    </ul>
                                ) : null}
                            </li>
                        )
                    } else {
                        return null;
                    }
                })}
            </>
        );
        // eslint-disable-next-line
    }, [activeLink, activeCommMenu, isPc]);

    // render academic menu
    const renderAcademicMenu = React.useCallback(() => {
        return (
            <>
                {academicMenuTitle.visible && (
                    <li className="nav-title">
                        <span>{academicMenuTitle.title}</span>
                    </li>
                )}
                {academicMenus.map((menu, index) => {
                    if (menu.visible) {
                        return (
                            <li className="nav-item" key={index} onClick={() => {
                                toggleSidebarContent(index, 'academic');
                                if (!menu.hasSubMenu) {
                                    if (isPc) {
                                        setIsOpen(true);
                                    }
                                }
                            }}>
                                <Link to={menu.link}
                                    className={`nav-link ${activeLink.includes(`/${menu.title.toLowerCase()}/`) ? "active" : ""}`}
                                >
                                    <i className={`material-icons-round`}>{menu.icon}</i>
                                    <span>{translate(menu.title)}</span>
                                </Link>
                                {menu.hasSubMenu && menu.subMenu ? (
                                    <ul className={`nav-content ${activeAcademicMenu === index ? "show" : ""}`}>
                                        {menu.subMenu.sort((a, b) => a.title.length - b.title.length).map((subMenu, indexTwo) => (
                                            subMenu.visible ? (
                                                <li key={indexTwo}>
                                                    <Link to={subMenu.link} className={`${activeLink.includes(subMenu.link) ? "active" : ""}`} onClick={() => {
                                                        if (isPc) {
                                                            setIsOpen(true);
                                                        }
                                                    }}>
                                                        <Icon name="chevron_right" type="round" />
                                                        <span>{translate(subMenu.title)}</span>
                                                    </Link>
                                                </li>
                                            ) : null
                                        ))}
                                    </ul>
                                ) : null}
                            </li>
                        )
                    } else {
                        return null;
                    }
                })}
            </>
        );
        // eslint-disable-next-line
    }, [activeLink, activeAcademicMenu, isPc]);

    // render administration menu
    const renderAdministrationMenu = React.useCallback(() => {
        return (
            <>
                {administrationMenuTitle.visible && (
                    <li className="nav-title">
                        <span>{administrationMenuTitle.title}</span>
                    </li>
                )}
                {administrationMenus.map((menu, index) => {
                    if (menu.visible) {
                        return (
                            <li className="nav-item" key={index} onClick={() => {
                                toggleSidebarContent(index, 'administration');
                                if (!menu.hasSubMenu) {
                                    if (isPc) {
                                        setIsOpen(true);
                                    }
                                }
                            }}>
                                <Link to={menu.link}
                                    className={`nav-link ${activeLink.includes(`/${menu.title.toLowerCase()}/`) ? "active" : ""}`}
                                >
                                    <i className={`material-icons-round`}>{menu.icon}</i>
                                    <span>{translate(menu.title)}</span>
                                </Link>
                                {menu.hasSubMenu && menu.subMenu ? (
                                    <ul className={`nav-content ${activeAdministrationMenu === index ? "show" : ""}`}>
                                        {menu.subMenu.sort((a, b) => a.title.length - b.title.length).map((subMenu, indexTwo) => (
                                            subMenu.visible ? (
                                                <li key={indexTwo}>
                                                    <Link to={subMenu.link} className={`${activeLink.includes(subMenu.link) ? "active" : ""}`} onClick={() => {
                                                        if (isPc) {
                                                            setIsOpen(true);
                                                        }
                                                    }}>
                                                        <Icon name="chevron_right" type="round" />
                                                        <span>{translate(subMenu.title)}</span>
                                                    </Link>
                                                </li>
                                            ) : null
                                        ))}
                                    </ul>
                                ) : null}
                            </li>
                        )
                    } else {
                        return null;
                    }
                })}
            </>
        );
        // eslint-disable-next-line
    }, [activeLink, activeAdministrationMenu, isPc]);

    // render digital menu
    const renderDigitalMenu = React.useCallback(() => {
        return (
            <>
                {digitalMenuTitle.visible && (
                    <li className="nav-title">
                        <span>{digitalMenuTitle.title}</span>
                    </li>
                )}
                {digitalMenus.map((menu, index) => {
                    if (menu.visible) {
                        return (
                            <li className="nav-item" key={index} onClick={() => {
                                toggleSidebarContent(index, 'digital');
                                if (!menu.hasSubMenu) {
                                    if (isPc) {
                                        setIsOpen(true);
                                    }
                                }
                            }}>
                                <Link to={menu.link}
                                    className={`nav-link ${activeLink.includes(`/${menu.title.toLowerCase()}/`) ? "active" : ""}`}
                                >
                                    <i className={`material-icons-round`}>{menu.icon}</i>
                                    <span>{translate(menu.title)}</span>
                                </Link>
                                {menu.hasSubMenu && menu.subMenu ? (
                                    <ul className={`nav-content ${activeDigitalMenu === index ? "show" : ""}`}>
                                        {menu.subMenu.sort((a, b) => a.title.length - b.title.length).map((subMenu, indexTwo) => (
                                            subMenu.visible ? (
                                                <li key={indexTwo}>
                                                    <Link to={subMenu.link} className={`${activeLink.includes(subMenu.link) ? "active" : ""}`} onClick={() => {
                                                        if (isPc) {
                                                            setIsOpen(true);
                                                        }
                                                    }}>
                                                        <Icon name="chevron_right" type="round" />
                                                        <span>{translate(subMenu.title)}</span>
                                                    </Link>
                                                </li>
                                            ) : null
                                        ))}
                                    </ul>
                                ) : null}
                            </li>
                        )
                    } else {
                        return null;
                    }
                })}
            </>
        );
        // eslint-disable-next-line
    }, [activeLink, activeDigitalMenu, isPc]);

    // render other menu
    const renderOtherMenu = React.useCallback(() => {
        return (
            <>
                {otherMenus.map((menu, index) => {
                    if (menu.visible) {
                        return (
                            <li className="nav-item" key={index} onClick={() => {
                                toggleSidebarContent(index, 'other');
                                if (!menu.hasSubMenu) {
                                    if (isPc) {
                                        setIsOpen(true);
                                    }
                                }
                            }}>
                                <Link to={menu.link}
                                    className={`nav-link ${activeLink.includes(`/${menu.title.toLowerCase()}/`) ? "active" : ""}`}
                                >
                                    <i className={`material-icons-round`}>{menu.icon}</i>
                                    <span>{translate(menu.title)}</span>
                                </Link>
                                {menu.hasSubMenu && menu.subMenu ? (
                                    <ul className={`nav-content ${activeOtherMenu === index ? "show" : ""}`}>
                                        {menu.subMenu.sort((a, b) => a.title.length - b.title.length).map((subMenu, indexTwo) => (
                                            subMenu.visible ? (
                                                <li key={indexTwo}>
                                                    <Link to={subMenu.link} className={`${activeLink.includes(subMenu.link) ? "active" : ""}`} onClick={() => {
                                                        if (isPc) {
                                                            setIsOpen(true);
                                                        }
                                                    }}>
                                                        <Icon name="chevron_right" type="round" />
                                                        <span>{translate(subMenu.title)}</span>
                                                    </Link>
                                                </li>
                                            ) : null
                                        ))}
                                    </ul>
                                ) : null}
                            </li>
                        )
                    } else {
                        return null;
                    }
                })}
            </>
        );
        // eslint-disable-next-line
    }, [activeLink, activeOtherMenu, isPc]);

    // render operation menu
    const renderOperationMenu = React.useCallback(() => {
        return (
            <>
                {operationMenuTitle.visible && (
                    <li className="nav-title">
                        <span>{operationMenuTitle.title}</span>
                    </li>
                )}
                {operationMenus.map((menu, index) => {
                    if (menu.visible) {
                        return (
                            <li className="nav-item" key={index} onClick={() => {
                                toggleSidebarContent(index, 'operation');
                                if (!menu.hasSubMenu) {
                                    if (isPc) {
                                        setIsOpen(true);
                                    }
                                }
                            }}>
                                <Link to={menu.link}
                                    className={`nav-link ${activeLink.includes(`/${menu.title.toLowerCase()}/`) ? "active" : ""}`}
                                >
                                    <i className={`material-icons-round`}>{menu.icon}</i>
                                    <span>{translate(menu.title)}</span>
                                </Link>
                                {menu.hasSubMenu && menu.subMenu ? (
                                    <ul className={`nav-content ${activeOperationMenu === index ? "show" : ""}`}>
                                        {menu.subMenu.sort((a, b) => a.title.length - b.title.length).map((subMenu, indexTwo) => (
                                            subMenu.visible ? (
                                                <li key={indexTwo}>
                                                    <Link to={subMenu.link} className={`${activeLink.includes(subMenu.link) ? "active" : ""}`} onClick={() => {
                                                        if (isPc) {
                                                            setIsOpen(true);
                                                        }
                                                    }}>
                                                        <Icon name="chevron_right" type="round" />
                                                        <span>{translate(subMenu.title)}</span>
                                                    </Link>
                                                </li>
                                            ) : null
                                        ))}
                                    </ul>
                                ) : null}
                            </li>
                        )
                    } else {
                        return null;
                    }
                })}
            </>
        );
        // eslint-disable-next-line
    }, [activeLink, activeOperationMenu, isPc]);

    // render account menu
    const renderAccountMenu = React.useCallback(() => {
        return (
            <>
                {accountMenuTitle.visible && (
                    <li className="nav-title">
                        <span>{accountMenuTitle.title}</span>
                    </li>
                )}
                {accountMenus.map((menu, index) => {
                    if (menu.visible) {
                        return (
                            <li className="nav-item" key={index} onClick={() => {
                                toggleSidebarContent(index, 'account');
                                if (!menu.hasSubMenu) {
                                    if (isPc) {
                                        setIsOpen(true);
                                    }
                                }
                            }}>
                                <Link to={menu.link}
                                    className={`nav-link ${activeLink.includes(`/${menu.title.toLowerCase()}/`) ? "active" : ""}`}
                                >
                                    <i className={`material-icons-round`}>{menu.icon}</i>
                                    <span>{translate(menu.title)}</span>
                                </Link>
                                {menu.hasSubMenu && menu.subMenu ? (
                                    <ul className={`nav-content ${activeAccountMenu === index ? "show" : ""}`}>
                                        {menu.subMenu.sort((a, b) => a.title.length - b.title.length).map((subMenu, indexTwo) => (
                                            subMenu.visible ? (
                                                <li key={indexTwo}>
                                                    <Link to={subMenu.link} className={`${activeLink.includes(subMenu.link) ? "active" : ""}`} onClick={() => {
                                                        if (isPc) {
                                                            setIsOpen(true);
                                                        }
                                                    }}>
                                                        <Icon name="chevron_right" type="round" />
                                                        <span>{translate(subMenu.title)}</span>
                                                    </Link>
                                                </li>
                                            ) : null
                                        ))}
                                    </ul>
                                ) : null}
                            </li>
                        )
                    } else {
                        return null;
                    }
                })}
            </>
        );
        // eslint-disable-next-line
    }, [activeLink, activeAccountMenu, isPc]);

    // CHANGING LANGUAGE
    const changeLanguage = async () => {
        try {
            let language = activeLanguage === "swahili" ? "english" :
                activeLanguage === "english" ? "swahili" : "english"
            storage.store("language", language)
            setActiveLanguage(language)
            const user = getInfo("user")
            const body = {
                schema: "user",
                condition: { _id: user._id },
                newDocumentData: {
                    $set: {
                        updated_by: user._id,
                        settings: {
                            ...user.settings,
                            language,
                        }
                    }
                }
            }
            let response = await (await fetch(`${serverUrl}/api/update`, {
                mode: "cors",
                method: "PUT",
                body: ToJSON(has_enable_encryption ? encrypt(body) : body),
                headers: {
                    "Content-Type": "application/json"
                }
            })).json()

            if (response.success) {
                storage.store("user", response.message)
                window.location.reload()
            }

        } catch (error) {
            console.log(`Language change error: ${error.message}`)
        }
    }

    const [showMenu, setShowMenu] = React.useState(false);

    const handleAvatarClick = () => {
        setShowMenu(!showMenu);
    };

    const handleMenuClose = () => {
        showMenu === true ? setShowMenu(false) : setShowMenu(true);
    };

    function handleClickOutside(event) {
        const menuElement = document.querySelector('.menu');
        const avatarElement = document.querySelector('.avatar');

        if (menuElement && !menuElement.contains(event.target) && !avatarElement.contains(event.target)) {
            setShowMenu(false);
        }
    }

    React.useEffect(() => {
        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, []);


    return (
        <>
            {/* APPBAR */}
            <div className={!isOpen === true ? 'toggle-sidebar' : ''}>
                <div className={`appbar hide-on-print`}>
                    <div className='toolbar'>
                        {
                            !isOpen
                                ?
                                <Humberg onClick={ToggleSidebar} />
                                :
                                <Humberg id="menu-btn" onClick={ToggleSidebar} />

                        }
                        <span className='title'>{(translate(title))}</span>
                    </div>
                    <div className='toolbar right-section'>
                        <ThemeSwitcher />
                        {/* <Icon name={"notifications_none"} type="round" /> */}
                        <span className='avatar' onClick={handleAvatarClick}>
                            {
                                getInfo("user", "logo") ?
                                    <div className='img'>
                                        <img src={`${socketURL}/uploads/upload/${user?.logo}`} alt="logo" crossOrigin="anonymous" />
                                    </div>
                                    :
                                    getInfo("user", "fullname")
                                        ?
                                        text.toTitle(getInfo("user", "fullname"))?.charAt(0)

                                        :
                                        text.toTitle(getInfo("user", "username"))?.charAt(0)
                            }
                        </span>
                        {showMenu && (
                            <div className='menu'>
                                <span className='title'>
                                    {text.toTitle(text.reFormat(getInfo("user", "username")))}
                                    {/* <span className="material-icons-round edit">
                                        edit
                                    </span> */}
                                </span>
                                <span className='avatari'>
                                    {text.toTitle(getInfo("user", "username"))?.charAt(0)}
                                </span>
                                <div className='view-names'>
                                    {
                                        getInfo("user", "logo") ?
                                            <div className='img'>
                                                <img src={`${socketURL}/uploads/upload/${user?.logo}`} alt="logo" crossOrigin="anonymous" />
                                            </div>
                                            :
                                            getInfo("user", "fullname") && getInfo("user", "lastname")
                                                ?
                                                <span>
                                                    {`${text.toTitle(getInfo("user", "fullname"))} ${text.toTitle(getInfo("user", "lastname"))}`}
                                                </span>
                                                :
                                                <span>
                                                    {text.toTitle(text.reFormat(getInfo("user", "username")))}
                                                </span>
                                    }
                                    <span>
                                        {

                                            text.toTitle(text.reFormat(getInfo("user", "school")?.name))
                                        }
                                    </span>
                                </div>
                                <ul className='menu-item'>
                                    <li className='item'>
                                        <Link to="/view-profile" className='item-link' onClick={handleMenuClose}>
                                            {translate("View Profile")}
                                        </Link>
                                    </li>
                                    <li className='item'>
                                        <Link to="/change-password" className='item-link' onClick={handleMenuClose}>
                                            {translate("Change password")}
                                        </Link>
                                    </li>
                                    <li className='item'>
                                        <Link to="#" className='item-link' onClick={() => {
                                            window.location.replace("/")
                                            props.authenticate("logout")
                                        }}>
                                            {translate("Logout")}
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {/* SIDEBAR */}
            <div className={!isOpen === true ? 'toggle-sidebar' : ''}>
                <aside id='sidebar' className={`sidebar hide-on-print`}>
                    <span className="" onClick={ToggleSidebar}>
                        <i className="material-icons-round toggle-sidebar-btn" >arrow_circle_left</i>
                    </span>
                    <div className='logo-container'>
                        <Link to="/" className="logo">
                            <img src={`${props.user?.school?.logo ? `${socketURL}/uploads/upload/${props.user.school.logo}` : logo}`} alt="logo" crossOrigin="anonymous" />
                            {/* <span>{applicationName}</span> */}
                        </Link>
                    </div>
                    <ul className="sidebar-nav" id="sidebar-nav">
                        {renderMainMenu()}
                        {renderAdministrationMenu()}
                        {renderAcademicMenu()}
                        {renderOperationMenu()}
                        {renderAccountMenu()}
                        {renderCommuncationMenu()}
                        {renderDigitalMenu()}
                        <li className="nav-title">
                            <span>OTHER</span>
                        </li>
                        {renderOtherMenu()}
                        <li className="nav-item">
                            <Link to="#" className="nav-link" onClick={changeLanguage}>
                                <Icon name="language" type="round" />
                                <span>{activeLanguage === "english" ? "use swahili" : "tumia kiingereza"}</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="#" className="nav-link" onClick={() => {
                                window.location.replace("/")
                                props.authenticate("logout")
                            }}>
                                <Icon name="logout" type="round" />
                                <span>{translate("Logout")}</span>
                            </Link>
                        </li>
                    </ul>
                </aside>
            </div>

            {/* OVERLAY*/}
            <div className={`sidebar-overlay ${isOpen === false ? 'active' : ''}`} onClick={ToggleSidebar}>
            </div>

            {/* MAIN */}
            <div className={!isOpen === true ? 'toggle-sidebar' : ''}>
                <main className="main" id="main">
                    {props.message ? <Toast message={props.message.toString()} icon={props.icon} type={props.type} /> : null}
                    {props.children}
                </main>
            </div>
        </>
    )
})

// EXPORT
export default Drawer