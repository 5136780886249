// states
const initial_state = {

    // system
    options: [],
    loading: false,
    disabled: false,
    edit: false,
    logo: null,
    logoError: '',

    // base
    id: '',
    notification: {
        message: '',
        type: ''
    },

    // user
    authenticated: false,
    username: '',
    usernameError: '',
    password: '',
    passwordError: '',
    confirmation: '',
    confirmationError: '',
    phone: '',
    phoneError: '',

    // school
    school_name: "",
    school_nameError: "",
    school_levels: [],
    school_levelsError: "",
    school_center_no: "",
    school_center_noError: "",

    school_id: "",
    school_list: [],
    school_sort: '',
    school_condition: '',
    school_list_order: '',
    school_list_pages: [],
    school_list_limit: 20,
    school_list_next_page: 0,
    school_list_current_page: 1,
    school_list_previous_page: 0,
    school_list_total_documents: 0,
    school_list_excel: [],

    school_data: "",

    // role
    role_name: '',
    role_nameError: '',
    role_description: '',
    role_descriptionError: '',
    role_permissions: [],
    role_permissionsError: '',

    role_id: "",
    role_list: [],
    role_sort: '',
    role_condition: '',
    role_list_order: '',
    role_list_pages: [],
    role_list_limit: 20,
    role_list_next_page: 0,
    role_list_current_page: 1,
    role_list_previous_page: 0,
    role_list_total_documents: 0,
    role_list_excel: [],

    role_data: "",

    // user
    user_fullname: "",
    user_fullnameError: "",
    user_school: "",
    user_schoolError: "",
    user_username: "",
    user_usernameError: "",
    user_phone: "",
    user_phoneError: "",
    user_email: "",
    user_emailError: "",
    user_dob: "",
    user_dobError: "",
    user_bank: "",
    user_account_number: "",
    user_gender: "",
    user_genderError: "",
    user_contract_type: "",
    user_contract_typeError: "",
    user_contract_start_date: "",
    user_contract_start_dateError: "",
    user_contract_end_date: "",
    user_contract_end_dateError: "",
    user_contract_notify_date: "",
    user_contract_notify_dateError: "",
    user_role: "",
    user_roleError: "",
    user_account_type: "",
    user_account_typeError: "",
    user_roles: [],
    user_schools: [],
    user_schoolsError: "",
    user_options: [],

    user_change_oldPassword: '',
    user_change_oldPasswordError: '',
    user_change_password: '',
    user_change_passwordError: '',
    user_change_passwordConfirmation: '',
    user_change_passwordConfirmationError: '',

    reset_data: null,
    data_reset: null,
    reset: '',
    resetError: '',

    user_password_resetConfirmation: '',
    user_password_resetConfirmationError: '',

    user_password_reset: '',
    user_password_resetError: '',

    email_forgot: '',
    email_forgotError: '',

    user_id: "",
    user_list: [],
    user_sort: '',
    user_condition: '',
    user_list_order: '',
    user_list_pages: [],
    user_list_limit: 20,
    user_list_next_page: 0,
    user_list_current_page: 1,
    user_list_previous_page: 0,
    user_list_total_documents: 0,
    user_list_excel: [],

    user_data: "",

    // teacher
    teacher_fullname: "",
    teacher_fullnameError: "",
    teacher_account_type: "",
    teacher_account_typeError: "",
    teacher_username: "",
    teacher_usernameError: "",
    teacher_gender: "",
    teacher_genderError: "",
    teacher_phone: "",
    teacher_phoneError: "",
    teacher_dob: "",
    teacher_dobError: "",
    teacher_email: "",
    teacher_emailError: "",
    teacher_role: "",
    teacher_roles: [],
    teacher_roleError: "",
    teacher_religion: "",
    teacher_religionError: "",
    teacher_photo: "",
    teacher_photoError: "",
    teacher_country: "Tanzania",
    teacher_countryError: "",
    teacher_region: "",
    teacher_regionError: "",
    teacher_district: "",
    teacher_districtError: "",
    teacher_street: "",
    teacher_streetError: "",
    teacher_physical_condition: "Normal",
    teacher_physical_conditionError: "",
    teacher_health_insurance: "",
    teacher_health_insuranceError: "",
    teacher_nida: "",
    teacher_nidaError: "",
    teacher_id_number: "",
    teacher_id_numberError: "",
    teacher_education: "",
    teacher_educationError: "",
    teacher_salary: "",
    teacher_salaryError: "",
    teacher_school: "",
    teacher_type: "",
    teacher_typeError: "",

    teacher_id: "",
    teacher_list: [],
    teacher_sort: '',
    teacher_condition: '',
    teacher_list_order: '',
    teacher_list_pages: [],
    teacher_list_limit: 20,
    teacher_list_next_page: 0,
    teacher_list_current_page: 1,
    teacher_list_previous_page: 0,
    teacher_list_total_documents: 0,
    teacher_list_excel: [],

    // staff
    staff_fullname: "",
    staff_fullnameError: "",
    staff_gender: "",
    staff_genderError: "",
    staff_phone: "",
    staff_phoneError: "",
    staff_dob: "",
    staff_dobError: "",
    staff_email: "",
    staff_emailError: "",
    staff_role: "",
    staff_roles: [],
    staff_roleError: "",
    staff_religion: "",
    staff_religionError: "",
    staff_photo: "",
    staff_photoError: "",
    staff_country: "Tanzania",
    staff_countryError: "",
    staff_region: "",
    staff_regionError: "",
    staff_district: "",
    staff_districtError: "",
    staff_street: "",
    staff_streetError: "",
    staff_physical_condition: "Normal",
    staff_physical_conditionError: "",
    staff_health_insurance: "",
    staff_health_insuranceError: "",
    staff_nida: "",
    staff_nidaError: "",
    staff_id_number: "",
    staff_id_numberError: "",
    staff_education: "",
    staff_educationError: "",
    staff_salary: "",
    staff_type: "",
    staff_marital: "",
    staff_salaryError: "",
    staff_nok_fullname: "",
    staff_nok_fullnameError: "",
    staff_nok_phone: "",
    staff_nok_phoneError: "",
    staff_nok_relation: "",
    staff_nok_relationError: "",


    staff_id: "",
    staff_list: [],
    staff_sort: '',
    staff_condition: '',
    staff_list_order: '',
    staff_list_pages: [],
    staff_list_limit: 20,
    staff_list_next_page: 0,
    staff_list_current_page: 1,
    staff_list_previous_page: 0,
    staff_list_total_documents: 0,
    staff_list_excel: [],

    // level
    level_name: "",
    level_rank: "average",
    level_nameError: "",
    level_number_of_classes: "",
    level_number_of_classesError: "",
    level_result_format: "",
    level_result_formatError: "",
    level_pass_mark: "",
    level_head_teacher: null,
    level_head_teachers: [],

    level_id: "",
    level_list: [],
    level_sort: '',
    level_condition: '',
    level_list_order: '',
    level_list_pages: [],
    level_list_limit: 20,
    level_list_next_page: 0,
    level_list_current_page: 1,
    level_list_previous_page: 0,
    level_list_total_documents: 0,
    level_list_excel: [],

    level_data: "",

    // academic year
    academic_year_name: "",
    academic_year_nameError: "",
    academic_year_level: "",
    academic_year_levels: [],
    academic_year_levelError: "",
    academic_year_start_date: "",
    academic_year_start_dateError: "",
    academic_year_end_date: "",
    academic_year_end_dateError: "",

    academic_year_id: "",
    academic_year_list: [],
    academic_year_sort: '',
    academic_year_condition: '',
    academic_year_list_order: '',
    academic_year_list_pages: [],
    academic_year_list_limit: 20,
    academic_year_list_next_page: 0,
    academic_year_list_current_page: 1,
    academic_year_list_previous_page: 0,
    academic_year_list_total_documents: 0,
    academic_year_list_excel: [],

    academic_year_data: "",

    // class
    class_name: "",
    class_nameError: "",
    class_level: "",
    class_levels: [],
    class_levelError: "",
    class_teacher: "",
    class_teacherError: "",
    class_teachers: [],

    class_id: "",
    class_list: [],
    class_sort: '',
    class_condition: '',
    class_list_order: '',
    class_list_pages: [],
    class_list_limit: 20,
    class_list_next_page: 0,
    class_list_current_page: 1,
    class_list_previous_page: 0,
    class_list_total_documents: 0,
    class_list_excel: [],

    class_data: "",

    // stream
    stream_name: "",
    stream_nameError: "",
    stream_type: "",
    stream_typeError: "",
    stream_class: "",
    stream_classes: [],
    stream_classError: "",
    stream_teacher: "",
    stream_teacherError: "",
    stream_teachers: [],

    stream_id: "",
    stream_list: [],
    stream_sort: '',
    stream_condition: '',
    stream_list_order: '',
    stream_list_pages: [],
    stream_list_limit: 20,
    stream_list_next_page: 0,
    stream_list_current_page: 1,
    stream_list_previous_page: 0,
    stream_list_total_documents: 0,
    stream_list_excel: [],

    stream_data: "",

    //  subject
    subject_name: "",
    subject_nameError: "",
    subject_arrangement: "",

    subject_id: "",
    subject_list: [],
    subject_sort: '',
    subject_condition: '',
    subject_list_order: '',
    subject_list_pages: [],
    subject_list_limit: 20,
    subject_list_next_page: 0,
    subject_list_current_page: 1,
    subject_list_previous_page: 0,
    subject_list_total_documents: 0,
    subject_list_excel: [],

    subject_data: "",

    // class subject
    class_subject_level: "",
    class_subject_levels: [],
    class_subject_levelError: "",
    class_subject_academic_year: "",
    class_subject_academic_years: [],
    class_subject_academic_yearError: "",
    class_subject_subject: "",
    class_subject_subjects: [],
    class_subject_subjectError: "",
    class_subject_stream: "",
    class_subject_streams: [],
    class_subject_streamError: "",
    class_subject_class: "",
    class_subject_classes: [],
    class_subject_classesError: "",
    class_subject_type: "",
    class_subject_typeError: "",
    class_subject_penalt_per_mark: "",
    class_subject_options: [],

    class_subject_id: "",
    class_subject_list: [],
    class_subject_sort: '',
    class_subject_condition: '',
    class_subject_list_order: '',
    class_subject_list_pages: [],
    class_subject_list_limit: 20,
    class_subject_list_next_page: 0,
    class_subject_list_current_page: 1,
    class_subject_list_previous_page: 0,
    class_subject_list_total_documents: 0,
    class_subject_list_excel: [],

    stream_ids: [],
    classroom_id: "",
    class_subject_data: "",

    // subject teacher
    subject_teacher_levels: [],
    subject_teacher_level: "",
    subject_teacher_levelError: "",
    subject_teacher_academic_years: [],
    subject_teacher_academic_year: "",
    subject_teacher_academic_yearError: "",
    subject_teacher_options: [],
    subject_teacher_classes: [],
    subject_teacher_classesError: "",

    subject_teacher_id: "",
    subject_teacher_list: [],
    subject_teacher_sort: '',
    subject_teacher_condition: '',
    subject_teacher_list_order: '',
    subject_teacher_list_pages: [],
    subject_teacher_list_limit: 20,
    subject_teacher_list_next_page: 0,
    subject_teacher_list_current_page: 1,
    subject_teacher_list_previous_page: 0,
    subject_teacher_list_total_documents: 0,
    subject_teacher_list_excel: [],

    subject_teacher_data: "",

    // allocate
    allocate_subject_list: [],
    allocate_teacher_options: [],
    allocate_subject_teachers: [],
    allocate_subject_level: '',
    allocate_subject_academic_year: '',

    // term
    term_name: "",
    term_nameError: "",
    term_level: "",
    term_levels: [],
    term_levelError: "",

    term_id: "",
    term_list: [],
    term_sort: '',
    term_condition: '',
    term_list_order: '',
    term_list_pages: [],
    term_list_limit: 20,
    term_list_next_page: 0,
    term_list_current_page: 1,
    term_list_previous_page: 0,
    term_list_total_documents: 0,
    term_list_excel: [],

    term_data: "",

    // installment
    installment_name: '',
    installment_nameError: '',
    installment_levels: [],
    installment_level: '',
    installment_levelError: '',
    installment_academic_years: [],
    installment_academic_year: "",
    installment_academic_yearError: "",
    installment_starting_date: "",
    installment_starting_dateError: "",
    installment_ending_date: "",
    installment_ending_dateError: "",

    installment_id: "",
    installment_list: [],
    installment_sort: '',
    installment_condition: '',
    installment_list_order: '',
    installment_list_pages: [],
    installment_list_limit: 20,
    installment_list_next_page: 0,
    installment_list_current_page: 1,
    installment_list_previous_page: 0,
    installment_list_total_documents: 0,
    installment_list_excel: [],

    installment_data: "",

    // bank
    bank_provider: "",
    bank_providerError: "",
    bank_branch: "",
    bank_branchError: "",
    bank_account: "",
    bank_accountError: "",
    bank_number: "",
    bank_numberError: "",
    bank_currency: "TZS",
    bank_currencyError: "",
    bank_amount: "",
    bank_amountError: "",
    bank_description: "",
    bank_descriptionError: "",

    bank_data: "",

    bank_id: "",
    bank_list: [],
    bank_sort: '',
    bank_condition: '',
    bank_list_order: '',
    bank_list_pages: [],
    bank_list_limit: 20,
    bank_list_next_page: 0,
    bank_list_current_page: 1,
    bank_list_previous_page: 0,
    bank_list_total_documents: 0,
    bank_list_excel: [],

    // grades
    grade: '',
    gradeError: '',
    grade_level: '',
    grade_levels: [],
    grade_levelError: '',
    grade_point: '',
    grade_pointError: '',
    grade_low_mark: '',
    grade_low_markError: '',
    grade_high_mark: '',
    grade_high_markError: '',
    grade_remark: '',
    grade_remarkError: '',
    grade_overall_remark: '',
    grade_overall_remarkError: '',
    grade_academic_remark: '',
    grade_academic_remarkError: '',

    grade_id: "",
    grade_list: [],
    grade_sort: '',
    grade_condition: '',
    grade_list_order: '',
    grade_list_pages: [],
    grade_list_limit: 20,
    grade_list_next_page: 0,
    grade_list_current_page: 1,
    grade_list_previous_page: 0,
    grade_list_total_documents: 0,
    grade_list_excel: [],

    grade_data: "",

    // exam
    exam: '',
    exams: [],
    examError: '',
    exam_level: '',
    exam_levels: [],
    exam_levelError: '',
    exam_levelsError: '',
    exam_options: [],
    exam_description: '',

    exam_id: "",
    exam_list: [],
    exam_sort: '',
    exam_condition: '',
    exam_list_order: '',
    exam_list_pages: [],
    exam_list_limit: 20,
    exam_list_next_page: 0,
    exam_list_current_page: 1,
    exam_list_previous_page: 0,
    exam_list_total_documents: 0,
    exam_list_excel: [],

    exam_data: "",

    exam_starting_date: '',
    exam_starting_dateError: '',
    exam_ending_date: '',
    exam_ending_dateError: '',

    info_name: '',
    info_nameError: '',
    info_level: '',
    info_levelError: '',
    info_exam: '',
    info_examError: '',
    info_classroom: '',
    info_edit_classrooms: [],
    info_classroomError: '',
    info_starting_date: '',
    info_starting_dateError: '',
    info_ending_date: '',
    info_ending_dateError: '',
    info_abbreviation: '',
    info_abbreviationError: '',
    info_exams: [],
    info_levels: [],
    info_options: [],
    info_classrooms: [],
    info_classroomsError: '',
    info_term: '',
    info_terms: [],
    info_termError: '',
    info_exam_term: '',
    info_exam_termError: '',
    info_exam_academic_years: [],
    info_exam_academic_year: '',
    info_exam_academic_yearError: '',

    exam_info_id: "",
    exam_info_list: [],
    exam_info_sort: '',
    exam_info_condition: '',
    exam_info_list_order: '',
    exam_info_list_pages: [],
    exam_info_list_limit: 20,
    exam_info_list_next_page: 0,
    exam_info_list_current_page: 1,
    exam_info_list_previous_page: 0,
    exam_info_list_total_documents: 0,
    exam_info_list_excel: [],

    exam_info_data: "",

    // dashboard
    males: "",
    females: "",
    graduated_males: 0,
    graduated_females: 0,
    graduated_total: 0,
    dashboard_exams: [],
    dashboard_messages: [],
    dashboard_message_count: '',
    parent_dashboard: 0,
    role_dashboard: 0,
    book_dashboard: 0,
    teacher_dashboard: 0,
    student_dashboard: 0,
    dashboard_news_list: [],
    dashboard_news_count: "",
    classroom_dashboard: 0,

    // necta
    necta_type: "",
    necta_typeError: "",
    necta_year: "",
    necta_yearError: "",
    necta_center_no: "",
    necta_center_noError: "",

    // student
    student_fullname: "",
    student_fullnameError: "",
    student_gender: "",
    student_genderError: "",
    student_level: "",
    student_levels: [],
    student_levelError: "",
    student_academic_year: "",
    student_academic_years: [],
    student_academic_yearError: "",
    student_class: "",
    student_classes: [],
    student_classError: "",
    student_stream: "",
    student_streams: [],
    student_streamError: "",
    student_reg: "",
    student_regError: "",
    student_dob: "",
    student_dobError: "",
    student_prem: "",
    student_admission_date: "",
    student_religion: "",
    student_photo: "",
    student_country: "Tanzania",
    student_region: "",
    student_district: "",
    student_street: "",
    student_disease: "",
    student_diseaseError: "",
    student_parent: "",
    student_parents: [],
    student_parentError: "",
    student_parent_fullname: "",
    student_parent_fullnameError: "",
    student_parent_phone: "",
    student_parent_phoneError: "",
    student_parent_type: "",
    student_parent_typeError: "",
    student_parent_email: "",
    student_parent_emailError: "",
    student_previous_school_type: "",
    student_previous_school: "",
    student_previous_schoolError: "",
    student_parent_role: "",
    student_parent_roles: [],
    student_parent_roleError: "",
    student_list_classrooms: [],
    student_data: "",
    student_report_list: [],

    student_upload_excel_levels: [],
    student_upload_excel_streams: [],
    student_upload_excel_classes: [],
    student_upload_excel_parent_roles: [],
    student_upload_excel_academic_years: [],

    student_uploaded_excel_data: [],

    student_id: "",
    student_list: [],
    student_sort: '',
    student_condition: '',
    student_list_order: '',
    student_list_pages: [],
    student_list_limit: 20,
    student_list_next_page: 0,
    student_list_current_page: 1,
    student_list_previous_page: 0,
    student_list_total_documents: 0,
    student_list_excel: [],

    // timetable
    timetable_type: "",
    timetable_typeError: "",
    timetable_level: "",
    timetable_levels: [],
    timetable_levelError: "",
    timetable_academic_year: "",
    timetable_academic_years: [],
    timetable_academic_yearError: "",
    timetable_class: "",
    timetable_classes: [],
    timetable_classError: "",
    timetable_term: "",
    timetable_terms: [],
    timetable_termError: "",
    timetable_no_of_periods_per_day: "",
    timetable_no_of_periods_per_dayError: "",
    timetable_class_subjects: [],

    timetable_table_days: {},
    // fee
    fee_name: "",
    fee_nameError: "",
    fee_priority: "",
    fee_priorityError: "",
    fee_banks: [],
    fee_classes: [],

    fee_id: "",
    fee_list: [],
    fee_sort: '',
    fee_condition: '',
    fee_list_order: '',
    fee_list_pages: [],
    fee_list_limit: 20,
    fee_list_next_page: 0,
    fee_list_current_page: 1,
    fee_list_previous_page: 0,
    fee_list_total_documents: 0,
    fee_list_excel: [],

    fee_data: "",

    // FEE structure
    fee_structure_name: "",
    fee_structure_nameError: "",
    fee_structure_level: "",
    fee_structure_levels: [],
    fee_structure_levelError: "",
    fee_structure_classroom: "",
    fee_structure_academic_year: "",
    fee_structure_academic_years: [],
    fee_structure_academic_yearError: "",
    fee_structure_installments: [],
    fee_structure_fees: [],
    fees: [],

    fee_structure_id: "",
    fee_structure_list: [],
    fee_structure_sort: '',
    fee_structure_condition: '',
    fee_structure_list_order: '',
    fee_structure_list_pages: [],
    fee_structure_list_limit: 20,
    fee_structure_list_next_page: 0,
    fee_structure_list_current_page: 1,
    fee_structure_list_previous_page: 0,
    fee_structure_list_total_documents: 0,
    fee_structure_list_excel: [],

    fee_structure_data: "",

    // discount
    fee_discount_level: "",
    fee_discount_levels: [],
    fee_discount_levelError: "",
    fee_discount_academic_year: "",
    fee_discount_academic_years: [],
    fee_discount_academic_yearError: "",
    fee_discount_classroom: "",
    fee_discount_classrooms: [],
    fee_discount_classroomError: "",
    fee_discount_fee: "",
    fee_discount_fees: [],
    fee_discount_feeError: "",
    fee_discount_student: "",
    fee_discount_students: [],
    fee_discount_studentError: "",
    fee_discount_detail: [],
    fee_discount_data: [],
    fee_discount_default: [],

    // unsubscribe
    fee_unsubscribe_level: "",
    fee_unsubscribe_levels: [],
    fee_unsubscribe_levelError: "",
    fee_unsubscribe_academic_year: "",
    fee_unsubscribe_academic_years: [],
    fee_unsubscribe_academic_yearError: "",
    fee_unsubscribe_classroom: "",
    fee_unsubscribe_classrooms: [],
    fee_unsubscribe_classroomError: "",
    fee_unsubscribe_fee: "",
    fee_unsubscribe_fees: [],
    fee_unsubscribe_feeError: "",
    fee_unsubscribe_student: "",
    fee_unsubscribe_students: [],
    fee_unsubscribe_studentError: "",
    fee_unsubscribe_detail: [],
    fee_unsubscribe_defaults: [],

    // news
    news_files: [],
    news_file: '',
    news_fileError: '',
    news_title: '',
    news_titleError: '',
    news_content: {},
    news_contentError: '',

    news_id: "",
    news_list: [],
    news_sort: '',
    news_condition: '',
    news_list_order: '',
    news_list_pages: [],
    news_list_limit: 20,
    news_list_next_page: 0,
    news_list_current_page: 1,
    news_list_previous_page: 0,
    news_list_total_documents: 0,
    news_list_excel: [],

    news_data: "",

    // mark
    mark_class: "",
    mark_classes: [],
    mark_classError: "",
    mark_academic_year: "",
    mark_academic_years: [],
    mark_academic_yearError: "",
    mark_term: "",
    mark_terms: [],
    mark_termError: "",
    mark_level: "",
    mark_levels: [],
    mark_levelError: "",
    mark_exam: "",
    mark_exams: [],
    mark_examError: "",
    mark_subject: "",
    mark_subjects: [],
    mark_subjectError: "",
    mark_stream: "",
    mark_streams: [],
    mark_streamError: "",

    mark_template_streams: [],
    mark_template_classrooms: [],
    student_mark_uploaded_excel_data: [],

    mark_template_class: "",
    mark_template_classError: "",
    mark_template_academic_year: "",
    mark_template_academic_yearError: "",
    mark_template_term: "",
    mark_template_termError: "",
    mark_template_level: "",
    mark_template_levelError: "",
    mark_template_exam: "",
    mark_template_examError: "",
    mark_template_subject: "",
    mark_template_subjectError: "",
    mark_template_stream: "",
    mark_template_streamError: "",

    // marking
    mark: "",
    mark_students: [],
    marking_list: [],
    mark_students_excel: [],

    // parent
    parent_fullname: "",
    parent_fullnameError: "",
    parent_phone: "",
    parent_phoneError: "",
    parent_role: "",
    parent_roles: [],
    parent_roleError: "",
    parent_email: "",
    parent_emailError: "",
    parent_type: "",
    parent_typeError: "",
    parent_student: [],
    parent_students: [],
    parent_studentError: "",
    parent_country: "Tanzania",
    parent_countryError: "",
    parent_region: "",
    parent_regionError: "",
    parent_district: "",
    parent_districtError: "",
    parent_street: "",
    parent_streetError: "",
    parent_physical_condition: "",
    parent_physical_conditionError: "",
    parent_occupation: "",
    parent_occupationError: "",
    parent_employer_type: "",
    parent_employer_typeError: "",
    parent_employer_name: "",
    parent_employer_nameError: "",
    parent_student_status: "",

    parent_id: "",
    parent_list: [],
    parent_sort: '',
    parent_condition: '',
    parent_list_order: '',
    parent_list_pages: [],
    parent_list_limit: 20,
    parent_list_next_page: 0,
    parent_list_current_page: 1,
    parent_list_previous_page: 0,
    parent_list_total_documents: 0,
    parent_list_excel: [],

    // invoice
    invoice_level: "",
    invoice_levels: [],
    invoice_levelError: "",
    invoice_classroom: "",
    invoice_classrooms: [],
    invoice_classroomError: "",
    invoice_academic_year: "",
    invoice_academic_years: [],
    invoice_academic_yearError: "",
    invoice_student: [],
    invoice_students: [],
    invoice_students_data: [],
    // invoice_student: '',
    invoice_options: [],
    invoice_studentError: "",
    invoice_detail: [],
    invoice_date: "",
    invoice_dateError: "",

    invoice_list_report_list: [],
    invoice_list_classrooms: [],

    invoice_id: "",
    invoice_list: [],
    invoice_sort: '',
    invoice_condition: '',
    invoice_list_order: '',
    invoice_list_pages: [],
    invoice_list_limit: 20,
    invoice_list_next_page: 0,
    invoice_list_current_page: 1,
    invoice_list_previous_page: 0,
    invoice_list_total_documents: 0,
    invoice_list_excel: [],

    invoice_data: "",

    // profile
    data: "",
    student_view_list: [],
    user_subjects: [],

    // SCHOOL CONFIG
    config_motto: '',
    config_alpha: 'EduBetter',
    config_center_no: '',
    config_phone: '',
    config_phoneError: '',
    config_box: '',
    config_street: '',
    config_district: '',
    config_region: '',
    config_logo: null,
    config_stamp: null,
    config_name: '',
    config_email: "",
    config_emailError: "",
    config_head_of_school: "",
    config_empty_mark: "-",

    // allowance
    allowance_name: "",
    allowance_nameError: "",
    allowance_category: "",
    allowance_categoryError: "",
    allowance_type: "",
    allowance_typeError: "",
    allowance_pension_status: "",
    allowance_pension_statusError: "",
    allowance_percentage: "",
    allowance_percentageError: "",
    allowance_amount: "",
    allowance_amountError: "",
    allowance_description: "",
    allowance_descriptionError: "",

    allowance_data: "",

    allowance_id: "",
    allowance_list: [],
    allowance_sort: '',
    allowance_condition: '',
    allowance_list_order: '',
    allowance_list_pages: [],
    allowance_list_limit: 20,
    allowance_list_next_page: 0,
    allowance_list_current_page: 1,
    allowance_list_previous_page: 0,
    allowance_list_total_documents: 0,
    allowance_list_excel: [],

    allocate_allowance_id: "",
    allocate_allowance_list: [],
    allocate_allowance_sort: '',
    allocate_allowance_condition: '',
    allocate_allowance_list_order: '',
    allocate_allowance_list_pages: [],
    allocate_allowance_list_limit: 20,
    allocate_allowance_list_next_page: 0,
    allocate_allowance_list_current_page: 1,
    allocate_allowance_list_previous_page: 0,
    allocate_allowance_list_total_documents: 0,
    allocate_allowance_list_excel: [],

    allocate_allowance_amounting: "",
    allocate_allowance_category: "",
    allocate_allowance_percentaging: "",
    allocate_allowance_amount: "",
    allocate_allowance_user: "",
    allocate_allowance_deadline: "",
    allocate_allowance_percentage: "",

    // deduction
    deduction_name: "",
    deduction_nameError: "",
    deduction_category: "",
    deduction_categoryError: "",
    deduction_type: "",
    deduction_typeError: "",
    deduction_percentage: "",
    deduction_percentageError: "",
    deduction_amount: "",
    deduction_amountError: "",
    deduction_description: "",
    deduction_descriptionError: "",

    deduction_data: "",

    deduction_id: "",
    deduction_list: [],
    deduction_sort: '',
    deduction_condition: '',
    deduction_list_order: '',
    deduction_list_pages: [],
    deduction_list_limit: 20,
    deduction_list_next_page: 0,
    deduction_list_current_page: 1,
    deduction_list_previous_page: 0,
    deduction_list_total_documents: 0,
    deduction_list_excel: [],

    allocate_deduction_id: "",
    allocate_deduction_list: [],
    allocate_deduction_sort: '',
    allocate_deduction_condition: '',
    allocate_deduction_list_order: '',
    allocate_deduction_list_pages: [],
    allocate_deduction_list_limit: 20,
    allocate_deduction_list_next_page: 0,
    allocate_deduction_list_current_page: 1,
    allocate_deduction_list_previous_page: 0,
    allocate_deduction_list_total_documents: 0,
    allocate_deduction_list_excel: [],

    allocate_deduction_amounting: "",
    allocate_deduction_category: "",
    allocate_deduction_percentaging: "",
    allocate_deduction_amount: "",
    allocate_deduction_user: "",
    allocate_deduction_deadline: "",
    allocate_deduction_percentage: "",

    // pension
    pension_name: "",
    pension_nameError: "",
    pension_fund: "",
    pension_fundError: "",
    pension_employer_percentage: "",
    pension_employer_percentageError: "",
    pension_employee_percentage: "",
    pension_employee_percentageError: "",
    pension_salary_type: "",
    pension_salary_typeError: "",
    employer_name: "",
    employer_nameError: "",
    pension_employer_reg_no: "",
    pension_employer_reg_noError: "",

    employer_data: "",

    pension_id: "",
    pension_list: [],
    pension_sort: '',
    pension_condition: '',
    pension_list_order: '',
    pension_list_pages: [],
    pension_list_limit: 20,
    pension_list_next_page: 0,
    pension_list_current_page: 1,
    pension_list_previous_page: 0,
    pension_list_total_documents: 0,
    pension_list_excel: [],

    allocate_pension_id: "",
    allocate_pension_list: [],
    allocate_pension_sort: '',
    allocate_pension_condition: '',
    allocate_pension_list_order: '',
    allocate_pension_list_pages: [],
    allocate_pension_list_limit: 20,
    allocate_pension_list_next_page: 0,
    allocate_pension_list_current_page: 1,
    allocate_pension_list_previous_page: 0,
    allocate_pension_list_total_documents: 0,
    allocate_pension_list_excel: [],

    allocate_pension_employer: "",
    allocate_pension_employee: "",
    allocate_pension_employee_percentage: "",
    allocate_pension_user: "",
    allocate_pension_membership: "",
    allocate_pension_employer_percentage: "",

    // salary
    salary_list: [],
    salary_list_excel: [],

    // results
    result_name: '',
    result_nameError: '',
    result_level: '',
    result_levels: [],
    result_levelError: '',
    result_term: '',
    result_termError: '',
    result_type: '',
    result_typeError: '',
    result_exam: '',
    result_exams: [],
    result_examError: '',
    result_classroom: '',
    result_classroomError: '',
    result_academic_year: '',
    result_academic_years: [],
    result_academic_yearError: '',
    result_subject: '',
    result_subjectError: '',
    result_classrooms: [],
    result_classroomsError: [],
    result_marks: [],
    result_list: [],
    result_options: [],
    result_data: '',
    result_view_data: '',
    result_subjects: [],
    result_student_subjects: [],
    optional_subject_count: '',
    result_grade_list: [],
    result_publish: false,
    result_returning_date: "",
    result_closing_date: "",
    school_report: '',
    school_reportError: '',
    school_reports: [],
    result_exam_weight: 200,
    result_exam_weightError: '',
    result_subject_count: 0,

    result_list_name: "",
    result_list_nameError: "",
    result_list_level: "",
    result_list_levels: [],
    result_list_levelError: "",
    result_list_type: "",
    result_list_typeError: "",
    result_list_term: "",
    result_list_terms: [],
    result_list_termError: "",
    result_list_classroom: "",
    result_list_classrooms: [],
    result_list_classroomError: "",
    result_list_academic_year: "",
    result_list_academic_years: [],
    result_list_academic_yearError: "",

    // REPORT ANALYSE
    school_report_analyse: '',
    school_report_analyseError: '',
    school_report_analyses: [],
    report_analyse_exam_weight: 200,
    report_analyse_exam_weightError: '',
    report_analyse_name: '',
    report_analyse_names: [],
    report_analyse_nameError: '',
    report_analyse_type: '',
    report_analyse_typeError: '',
    report_analyse_exam: '',
    report_analyse_examError: '',
    report_analyse_subject: '',
    report_analyse_subjectError: '',
    report_analyse_classroom: '',
    report_analyse_classroomError: '',
    report_analyse_exams: [],
    report_analyse_classrooms: [],
    report_analyse_classroomsError: [],
    report_analyse_marks: [],
    report_analyse_list: [],
    report_analyse_level_passmark: 0,

    // sponsor
    sponsor_fullname: "",
    sponsor_fullnameError: "",
    sponsor_type: "",
    sponsor_typeError: "",
    sponsor_phone: "",
    sponsor_phoneError: "",
    sponsor_email: "",
    sponsor_emailError: "",
    sponsor_role: "",
    sponsor_roles: [],
    sponsor_roleError: "",
    sponsor_country: "Tanzania",
    sponsor_countryError: "",
    sponsor_region: "",
    sponsor_regionError: "",
    sponsor_district: "",
    sponsor_districtError: "",
    sponsor_street: "",
    sponsor_streetError: "",
    sponsor_student: [],
    sponsor_students: [],
    sponsor_studentError: "",
    sponsor_student_status: "",
    sponsor_student_statusError: "",

    sponsor_id: "",
    sponsor_list: [],
    sponsor_sort: '',
    sponsor_condition: '',
    sponsor_list_order: '',
    sponsor_list_pages: [],
    sponsor_list_limit: 20,
    sponsor_list_next_page: 0,
    sponsor_list_current_page: 1,
    sponsor_list_previous_page: 0,
    sponsor_list_total_documents: 0,
    sponsor_list_excel: [],

    // analysis
    analysis_module: "",
    student_analysis_below18: "",
    student_analysis_between18and20: "",
    student_analysis_above20: "",
    student_analysis_males: "",
    student_analysis_females: "",
    student_analysis_students: "",
    student_analysis_government: "",
    student_analysis_non_government: "",

    student_analysis_alcers: "",
    student_analysis_normal: "",
    student_analysis_arthritis: "",
    student_analysis_kidneyDisease: "",
    student_analysis_asthma: "",
    student_analysis_allergies: "",
    student_analysis_ibs: "",
    student_analysis_backPain: "",
    student_analysis_cancer: "",
    student_analysis_chronicFatigue: "",
    student_analysis_copd: "",
    student_analysis_diabetes: "",
    student_analysis_epilepsy: "",
    student_analysis_fibromyalgia: "",
    student_analysis_heartDisease: "",
    student_analysis_highBloodPressure: "",
    student_analysis_liverDisease: "",
    student_analysis_migraine: "",
    student_analysis_ms: "",
    student_analysis_osteoporosis: "",
    student_analysis_parkinsons: "",
    student_analysis_psoriasis: "",
    student_analysis_rheumatoidArthritis: "",
    student_analysis_sleepApnea: "",
    student_analysis_stroke: "",
    student_analysis_thyroidDisorders: "",

    parent_analysis_counts: "",
    parent_analysis_alcers: "",
    parent_analysis_normal: "",
    parent_analysis_arthritis: "",
    parent_analysis_kidneyDisease: "",
    parent_analysis_asthma: "",
    parent_analysis_allergies: "",
    parent_analysis_ibs: "",
    parent_analysis_backPain: "",
    parent_analysis_cancer: "",
    parent_analysis_chronicFatigue: "",
    parent_analysis_copd: "",
    parent_analysis_diabetes: "",
    parent_analysis_epilepsy: "",
    parent_analysis_fibromyalgia: "",
    parent_analysis_heartDisease: "",
    parent_analysis_highBloodPressure: "",
    parent_analysis_liverDisease: "",
    parent_analysis_migraine: "",
    parent_analysis_ms: "",
    parent_analysis_osteoporosis: "",
    parent_analysis_parkinsons: "",
    parent_analysis_psoriasis: "",
    parent_analysis_rheumatoidArthritis: "",
    parent_analysis_sleepApnea: "",
    parent_analysis_stroke: "",
    parent_analysis_thyroidDisorders: "",

    parent_analysis_mother: "",
    parent_analysis_father: "",
    parent_analysis_brother: "",
    parent_analysis_sister: "",
    parent_analysis_aunt: "",
    parent_analysis_uncle: "",
    parent_analysis_singleMother: "",
    parent_analysis_singleFather: "",
    parent_analysis_guardian: "",
    parent_analysis_other: "",
    parent_analysis_sponsor: "",
    parent_analysis_professions: "",

    // message
    message_source: "from system",
    message_sourceError: "",
    message_to: '',
    message_toError: '',
    message_data: [],
    message_name: '',
    message_nameError: '',
    message_files: [],
    message_file: '',
    message_fileError: '',
    message_title: '',
    message_titleError: '',
    message_content: {},
    message_criteria: '',
    message_criteriaError: '',
    message_class: "",
    message_classError: "",
    message_infos: [],
    parent_message: [],
    external_contacts: "",

    // topic
    topic_name: "",
    topic_nameError: "",
    topic_subject: "",
    topic_subjects: [],
    topic_subjectError: "",
    topic_level: "",
    topic_levels: [],
    topic_levelError: "",
    topic_class: "",
    topic_classes: [],
    topic_classError: "",
    topic_subtopic: "",
    topic_subtopicError: "",

    topic_uploaded_excel_data: [],

    topic_id: "",
    topic_list: [],
    topic_sort: '',
    topic_condition: '',
    topic_list_order: '',
    topic_list_pages: [],
    topic_list_limit: 20,
    topic_list_next_page: 0,
    topic_list_current_page: 1,
    topic_list_previous_page: 0,
    topic_list_total_documents: 0,
    topic_list_excel: [],

    // reference
    reference_subject: "",
    reference_subjects: [],
    reference_subjectError: "",
    reference_level: "",
    reference_levels: [],
    reference_levelError: "",
    reference_class: "",
    reference_classes: [],
    reference_classError: "",
    reference: "",
    referenceError: "",

    reference_uploaded_excel_data: [],

    reference_id: "",
    reference_list: [],
    reference_sort: '',
    reference_condition: '',
    reference_list_order: '',
    reference_list_pages: [],
    reference_list_limit: 20,
    reference_list_next_page: 0,
    reference_list_current_page: 1,
    reference_list_previous_page: 0,
    reference_list_total_documents: 0,
    reference_list_excel: [],

    reference_data: "",

    // sow
    scheme_of_work_level: "",
    scheme_of_work_levels: [],
    scheme_of_work_levelError: "",
    scheme_of_work_academic_year: "",
    scheme_of_work_academic_years: [],
    scheme_of_work_academic_yearError: "",
    scheme_of_work_class: "",
    scheme_of_work_classes: [],
    scheme_of_work_classError: "",
    scheme_of_work_subject: "",
    scheme_of_work_subjects: [],
    scheme_of_work_subjectError: "",
    scheme_of_work_topic: "",
    scheme_of_work_topics: [],
    scheme_of_work_topicError: "",
    scheme_of_work_subtopic: "",
    scheme_of_work_subtopics: [],
    scheme_of_work_subtopicError: "",
    scheme_of_work_month: "",
    scheme_of_work_months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ],
    scheme_of_work_monthError: "",
    scheme_of_work_week: "",
    scheme_of_work_weeks: ["1", "2", "3", "4"],
    scheme_of_work_weekError: "",
    scheme_of_work_period: "",
    scheme_of_work_periodError: "",
    scheme_of_work_competence: "",
    scheme_of_work_competenceError: "",
    scheme_of_work_objective: "",
    scheme_of_work_objectiveError: "",
    scheme_of_work_teaching_activities: "",
    scheme_of_work_teaching_activitiesError: "",
    scheme_of_work_learning_activities: "",
    scheme_of_work_learning_activitiesError: "",
    scheme_of_work_learning_aids: "",
    scheme_of_work_learning_aidsError: "",
    scheme_of_work_assessment: "",
    scheme_of_work_assessmentError: "",
    scheme_of_work_reference: [],
    scheme_of_work_references: [],
    scheme_of_work_referenceError: "",
    scheme_of_work_remark: "",

    scheme_of_work_list_level: "",
    scheme_of_work_list_levels: [],
    scheme_of_work_list_levelError: "",
    scheme_of_work_list_academic_year: "",
    scheme_of_work_list_academic_years: [],
    scheme_of_work_list_academic_yearError: "",
    scheme_of_work_list_class: "",
    scheme_of_work_list_classes: [],
    scheme_of_work_list_classError: "",
    scheme_of_work_list_subject: "",
    scheme_of_work_list_subjects: [],
    scheme_of_work_list_subjectError: "",

    scheme_of_work_data: "",

    scheme_list: [],
    scheme_level: "",
    scheme_class: "",
    scheme_subject: "",
    scheme_academic_year: "",

    // log book
    log_book_level: "",
    log_book_levels: [],
    log_book_levelError: "",
    log_book_academic_year: "",
    log_book_academic_years: [],
    log_book_academic_yearError: "",
    log_book_class: "",
    log_book_classes: [],
    log_book_classError: "",
    log_book_subject: "",
    log_book_subjects: [],
    log_book_subjectError: "",
    log_book_topic: "",
    log_book_topics: [],
    log_book_topicError: "",
    log_book_subtopic: "",
    log_book_subtopics: [],
    log_book_subtopicError: "",
    log_book_content: "",
    log_book_contentError: "",
    log_book_date_started: "",
    log_book_date_startedError: "",
    log_book_date_ended: null,
    log_book_hod_comment: "",
    log_book_teacher_comment: "",
    log_book_head_teacher_comment: "",

    log_book_list_level: "",
    log_book_list_levels: [],
    log_book_list_levelError: "",
    log_book_list_academic_year: "",
    log_book_list_academic_years: [],
    log_book_list_academic_yearError: "",
    log_book_list_class: "",
    log_book_list_classes: [],
    log_book_list_classError: "",
    log_book_list_subject: "",
    log_book_list_subjects: [],
    log_book_list_subjectError: "",

    logbook_id: "",
    logbook_list: [],
    logbook_level: "",
    logbook_class: "",
    logbook_subject: "",
    logbook_academic_year: "",

    // lesson plan
    lesson_plan_level: "",
    lesson_plan_levels: [],
    lesson_plan_levelError: "",
    lesson_plan_academic_year: "",
    lesson_plan_academic_years: [],
    lesson_plan_academic_yearError: "",
    lesson_plan_class: "",
    lesson_plan_classes: [],
    lesson_plan_classError: "",
    lesson_plan_subject: "",
    lesson_plan_subjects: [],
    lesson_plan_subjectError: "",
    lesson_plan_stream: "",
    lesson_plan_streams: [],
    lesson_plan_streamError: "",
    lesson_plan_topic: "",
    lesson_plan_topics: [],
    lesson_plan_topicError: "",
    lesson_plan_subtopic: "",
    lesson_plan_subtopics: [],
    lesson_plan_subtopicError: "",
    lesson_plan_period: "",
    lesson_plan_periodError: "",
    lesson_plan_present_students: "",
    lesson_plan_present_studentsError: "",
    lesson_plan_competence: "",
    lesson_plan_competenceError: "",
    lesson_plan_general_objective: "",
    lesson_plan_general_objectiveError: "",
    lesson_plan_specific_objective: "",
    lesson_plan_specific_objectiveError: "",
    lesson_plan_teaching_material: "",
    lesson_plan_teaching_materialError: "",
    lesson_plan_teaching_aids: "",
    lesson_plan_teaching_aidsError: "",
    lesson_plan_student_evaluation: "",
    lesson_plan_student_evaluationError: "",
    lesson_plan_teacher_evaluation: "",
    lesson_plan_teacher_evaluationError: "",
    lesson_plan_reamrk: "",
    lesson_plan_remarkError: "",

    // ATTENDANCE
    attendance_classes: [],
    attendance_class: '',
    attendance_classError: '',
    attendance_streams: [],
    attendance_stream: '',
    attendance_streamError: '',
    attendance_date: '',
    attendance_dateError: '',
    attendance_time: '',
    attendance_timeError: '',
    attendance_academic_years: [],
    attendance_academic_year: "",
    attendance_academic_yearError: "",
    attendance_level: "",
    attendance_levels: [],
    attendance_levelError: "",

    attendance_list: [],
    attendance_student_list: [],
    attendance_message_alpha: "",
    attendance_message_balance: "",

    // hostel
    hostel_name: "",
    hostel_nameError: "",
    hostel_guardians: [],
    hostel_guardian: "",
    hostel_guardianError: "",
    hostel_desc: "",
    hostel_descError: "",

    hostel_id: "",
    hostel_list: [],
    hostel_sort: '',
    hostel_condition: '',
    hostel_list_order: '',
    hostel_list_pages: [],
    hostel_list_limit: 20,
    hostel_list_next_page: 0,
    hostel_list_current_page: 1,
    hostel_list_previous_page: 0,
    hostel_list_total_documents: 0,
    hostel_list_excel: [],

    hostel_data: "",

    room_name: "",
    room_nameError: "",
    room_hostels: [],
    room_hostel: "",
    room_hostelError: "",
    room_bed: "",
    room_bedError: "",

    room_id: "",
    room_list: [],
    room_sort: '',
    room_condition: '',
    room_list_order: '',
    room_list_pages: [],
    room_list_limit: 20,
    room_list_next_page: 0,
    room_list_current_page: 1,
    room_list_previous_page: 0,
    room_list_total_documents: 0,
    room_list_excel: [],

    room_data: "",

}

// Export
export default initial_state